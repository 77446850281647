import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { JobOpeningContext } from "../../Context/JobOpeningContext";

const JobOpening = () => {
  const { rawJobPosting, isLoading } = useContext(JobOpeningContext);
  console.log(rawJobPosting);
  const navigate = useNavigate();

  const onChangeMenu = (jobdata) => {
    navigate(`/${jobdata.url_required}`, {
      state: { jobdata: jobdata },
    });
  };

  return (
    <>
      <Helmet>
        <title>Careers | Job Vacancy In Mumbai | Fluidscapes</title>
        <meta
          name="description"
          content="Discover exciting job opportunities in Fluidscapes Mumbai and seize your chance to embark on a fulfilling career journey. Join us now!"
        />
        <meta name="Keywords" content="job vacancy in mumbai" />
        <link rel="canonical" href="https://career.fluidscapes.in" />
      </Helmet>

      <div className="container mt-6 pb-5">
        <div className="row justify-content-center">
          {isLoading ? (
            <div> Loading.... </div>
          ) : (
            <>
              {rawJobPosting.length === 0 && (
                <div> No data Found. Please add JobOpening </div>
              )}

              {rawJobPosting.length > 0 &&
                rawJobPosting.map((jobopen) => {
                  return (
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-profile">
                        <div className="card-body pt-4">
                          {jobopen?.priority === "High" ? (
                            <span
                              className="badge badge-dim bg-primary priority"
                              style={{ color: "red" }}
                            >
                              High
                            </span>
                          ) : jobopen?.priority === "Medium" ? (
                            <span
                              className="badge badge-dim bg-primary priority"
                              style={{ color: "orange" }}
                            >
                              Medium
                            </span>
                          ) : jobopen?.priority === "Low" ? (
                            <span
                              className="badge badge-dim bg-primary priority"
                              style={{ color: "skyblue" }}
                            >
                              Low
                            </span>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div className="col">
                              <div className="text-center">
                                <h5 className="h2">
                                  {jobopen?.job_title || "-"}
                                </h5>
                                <div className="h5 font-weight-300">
                                  <i className="fas fa-map-marker-alt mr-2"></i>
                                  {jobopen?.location || "-"}
                                </div>
                              </div>
                              <div
                                className="card-profile-stats d-flex justify-content-center"
                                style={{
                                  borderTop: "1px dashed #ccc",
                                  padding: "1rem",
                                }}
                              >
                                <div style={{ borderRight: "1px dashed #ccc" }}>
                                  <span className="heading">{jobopen?.vacancy}</span>
                                  <span className="description">Vacancy</span>
                                </div>
                                <div>
                                  <span className="heading">
                                    {jobopen?.experience || "-"}
                                  </span>
                                  <span className="description">
                                    Experience
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row pt-3"
                            style={{ borderTop: "1px dashed #ccc" }}
                          >
                            <div className="col text-center">
                              {jobopen?.seo_title &&
                                jobopen?.url_required ? (
                                <button
                                  onClick={() => onChangeMenu(jobopen)}
                                  className="btn btn-info"
                                  style={{ fontSize: "14px" }}
                                  id="button-button-viewjobdetails"
                                >
                                  View
                                </button>
                              ) : (
                                <button
                                  className="btn btn-info"
                                  style={{ fontSize: "14px" }}
                                  id="button-button-viewjobdetails"
                                >
                                  View
                                </button>
                              )}
                            </div>
                          </div>
                          {/* <!--</div>--> */}
                        </div>
                      </div>
                      {/* <!-- .card --> */}
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default JobOpening;
