import React, { Suspense, useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import DefaultDepends from "../WorkflowStepperForm/DefaultDepends";
import SVG1 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_Sitting White.svg'
import SVG2 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_Running White.svg'
import SVG3 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_holding flag White.svg'
import SVG4 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_walking with flag White.svg'
import SVG5 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_P7.svg'
import SVG6 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_Flag Blue.svg'
import SVG7 from '../../../../Resources/images/Runner/CC_Progress_Bar_CR-1_Flag White.svg'


const NestedStepperForm = (props) => {
    const privileges = secureLocalStorage.getItem("privileges");
    // const privileges = [
    //     "Upload Assignment",
    //     "KYC",
    //     "Send Offer",
    //     "Telephonic Interview",
    //     "HR Interview",
    //     "Technical Interview",
    //     "Test",
    //     "Smart Hire Form",
    // ];

    console.log("privileges: ", privileges)
    console.log(props.data.tabs);
    const filterTabsWithForms = (tabs) => {
        console.log(tabs)
        return tabs.flatMap(tab => {
            const hasForm = tab.form !== null;
            console.log(hasForm)
            const filteredNestedTabs = hasForm ? [] : filterTabsWithForms(tab.tabs);
            console.log(filteredNestedTabs)
            return [
                hasForm ? tab : null,
                ...filteredNestedTabs,
            ];
        }).filter(tab => tab !== null && tab.form.form_name !== "Personal Details Retrieve" && tab.form.form_name !== "Educational Details Retrieve" && tab.form.form_name !== "Professional Details Retrieve" && tab.form.form_name !== "Additional Details Retrieve");
    }
    const filteredTabs = filterTabsWithForms(props.data.tabs);
    console.log(filteredTabs)


    const [FinalFilteredTabs, setFinalFilteredTabs] = useState([]);
    console.log(FinalFilteredTabs)

    const getSvgBasedOnProgress = (progress) => {
        if (progress === 0) return SVG1;
        if (progress > 0 && progress <= 20) return SVG1;
        if (progress > 20 && progress <= 90) return SVG2;
        if (progress > 90 && progress < 90) return SVG3;
        if (progress >= 90 && progress < 100) return SVG4;
        if (progress === 100) return SVG5;
        return null; // Fallback if progress is undefined or invalid
    };

    useEffect(() => {
        const accessibleStages = filteredTabs.filter((stage) => {
            if (!stage.form) return false;
            const formName = stage.form.form_name;
            return privileges.includes(formName);
        });
        setFinalFilteredTabs(accessibleStages);
    }, []);


    // Context API hook for getting current state of sequence context
    // const sequenceState = useSelector((state) => state.sequence);
    const { currentSequence, sequenceList, setLevel, currentForm } =
        useContext(SequenceContext);

    console.log(currentForm);

    console.log("currentSequence:", currentSequence);
    console.log("sequenceList:", sequenceList);
    const { workflowSession } = useContext(WorkflowContext);
    console.log("workflowSession:", workflowSession);

    const session_store = workflowSession; //Using API data
    console.log(session_store);

    const [showFlag, setShowFlag] = useState(false)
    console.log(showFlag)
    // Loading Forms dynamically using react lazy
    // parameter 'form' refers to component name
    const loadForm = (form) => {
        console.log(form);
        // console.log(currentForm);
        if (form.form !== null && form.form !== undefined
            //  && currentForm !== null
        ) {
            // if (currentForm.form_name === form.form.form_name) {
            console.log(form.form.form_path);
            // console.log(currentForm.form_name);
            const Form = React.lazy(() => import(`../${form.form.form_path}`));
            return (props) => <Form {...props} formId={form} />;
            // return Form;
            // }
        }
        return React.lazy(() => import(`../WorkflowStepperForm/EmptyForm`));
    };

    const getStageColor = (innerStepClass, stage, stage_status) => {
        if (innerStepClass === 'progress-step-inner') {
            return '#dcdcdc';
        } else {
            if (stage_status === 'not_shortlisted') {
                return '#fc2121'; //RED
            } else {
                return stage === 1 ? '#ff7800' : stage === 2 ? '#ffff00' : stage === 3 ? '#1fab3e' : '#dcdcdc';
            }
        }
    };

    const getStageBorder = (stepClass, stage, stage_status) => {
        if (stepClass === 'progress-step') {
            return '#dcdcdc';
        } else {
            if (stage_status === 'not_shortlisted') {
                return '2px solid #fc2121'; //RED
            } else {
                return stage === 1 ? '2px solid #ff7800' : stage === 2 ? '2px solid yellow' : stage === 3 ? '2px solid #1fab3e' : '#dcdcdc';
            }
        }
    }

    useEffect(() => {
        // Check if `session_store` includes any tab with `form_name` as "KYC"
        const isShowFlag = FinalFilteredTabs.some(
            (tab) => tab.form.form_name === "KYC" && session_store.some((session) => session.tab_id === tab.id)
        );
        setShowFlag(isShowFlag);
    }, [session_store, FinalFilteredTabs]);

    return (
        <>
            <div class="card">
                <div class="card-inner-group p-4">
                    <h6 class="h6">Hiring Pipeline</h6>
                    <div class="card mt-4" style={{ borderRadius: "0px", border: "1px solid #c2c2c2" }}>
                        <div className='progress-percent'>
                            <div class="loading-container">
                                <div class="loading-bar" style={{
                                    width: props.data?.total_progress < 8 ? "0%" : `${props.data?.total_progress}%`,
                                }}>
                                    <div class="percentage" style={{ right: (props.data?.total_progress === 0 || props.data?.total_progress < 8) ? "-70px" : "-2px" }}>
                                        <span>{props.data?.total_progress}%</span>
                                        <img
                                            src={getSvgBasedOnProgress(props.data?.total_progress)}
                                            alt="okay"
                                            style={{
                                                width: "1.5rem",
                                                height: "1.5rem",
                                                zIndex: 2
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='progressbody-wrapper'>
                            {FinalFilteredTabs.map((tab, i) => {
                                if (tab?.name === 'KYC' && !showFlag) {
                                    const kycStageElement = document.querySelector(`.progress-section[data-index="${i}"]`);

                                    const kycPositionLeft = kycStageElement ? kycStageElement.offsetLeft + 120 : 0;
                                    console.log("kycStageElement: ", kycStageElement)
                                    console.log("kycPositionLeft: ", kycPositionLeft)

                                    return (
                                        <img
                                            key="kyc-image"
                                            src={SVG6}
                                            alt="stage-icon"
                                            style={{
                                                position: "absolute",
                                                top: "-5rem",
                                                left: `${kycPositionLeft}px`,
                                                transform: "translateX(0)",
                                                width: "5rem",
                                                height: "5rem",
                                                zIndex: 10,
                                            }}
                                        />
                                    );
                                }
                                return null; // Return null for other stages, no image needed
                            })}
                            <div className='progressbody'>
                                {/* <ul className=""> */}
                                {FinalFilteredTabs ? FinalFilteredTabs.map((tab, i) => {
                                    let changetab = -1;
                                    if (props.level === 0) {
                                        changetab = sequenceList.findIndex(
                                            (sequence) => sequence[props.level] === i
                                        );
                                    } else {
                                        changetab = sequenceList.findIndex((sequence) => {
                                            // Check if the sequence length is at least as long as the currentSequence
                                            if (sequence.length >= props.level + 1) {
                                                // Check if the elements of the sequence match the currentSequence up to targetIndex
                                                for (let i = 0; i < props.level; i++) {
                                                    if (sequence[i] !== currentSequence[i]) {
                                                        return false;
                                                    }
                                                }
                                                // Check if the element at targetIndex matches targetValue
                                                if (sequence[props.level] === i) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        });
                                    }
                                    return (
                                        <div
                                            className='progress-section'
                                            key={i}
                                            data-index={i}
                                            onClick={() => {
                                                console.log(changetab)
                                                if (changetab !== -1) {
                                                    setLevel(changetab);
                                                    console.log(changetab);
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                                overflow: "visible", // Allow content to overflow
                                                zIndex: 2, // Ensure it's above other elements
                                            }}
                                        // className={`nav-item ${i === currentSequence[props.level] ? "active" : ""
                                        //     }`}
                                        // id={`nav-${tab.id}-tab`}
                                        // data-bs-toggle="tab"
                                        // data-bs-target={`#tab${tab.id}`}
                                        // type="button"
                                        // role="tab"
                                        >
                                            {/* {tab?.name === 'KYC' && !showFlag &&
                                                <img
                                                    src={require("../../../../Resources/images/mission.png")}
                                                    alt="okay"
                                                    style={{
                                                        position: "absolute",
                                                        right: "0",
                                                        top: "0",
                                                        width: "2rem",
                                                        height: "2rem"
                                                    }}
                                                    data-toggle="tooltip" data-placement="top" title="Congratulations on reaching this stage! Just a few more steps to go."
                                                />
                                            } */}
                                            <h5> {tab?.name} </h5>
                                            <div className="progressbar">
                                                {[1, 2, 3].map((index) => {
                                                    const stepClass = index <= tab?.stage ? `progress-step-stage-${tab?.stage}` : 'progress-step';
                                                    const innerStepClass = index <= tab?.stage ? `progress-step-inner-stage-${tab?.stage}` : 'progress-step-inner';
                                                    return (
                                                        <div className={stepClass} key={index} style={{ border: getStageBorder(stepClass, tab?.stage, tab?.stage_status) }}>
                                                            <div className={innerStepClass} style={{ backgroundColor: getStageColor(innerStepClass, tab?.stage, tab?.stage_status) }}></div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <p style={{ color: getStageColor('progress-step-inner-stage', tab?.stage, tab?.stage_status), fontSize: "12px", fontWeight: "500", textWrap: "nowrap" }}>
                                                {'(' + tab?.stage_status + ')'}
                                            </p>
                                        </div>
                                    );
                                }) : ""}
                                {/* </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="nav-tabContent">
                {FinalFilteredTabs ? FinalFilteredTabs.map((tab, i) => {
                    console.log(tab);
                    const Form = loadForm(tab);

                    // should_render_form checks the form name available in privilleges or not.
                    const shouldRenderForm = tab.form
                        ? privileges.includes(tab.form.form_name)
                        : true;
                    console.log("shouldRenderForm: ", shouldRenderForm);

                    let dependsOn;
                    {
                        tab.depends_on.map((depend) => {
                            console.log("depend:", depend);
                            if (depend === 0) {
                                dependsOn = true;
                            } else {
                                dependsOn = tab.depends_on.every((dependsOn) =>
                                    session_store.some((session) => session.tab_id === dependsOn)
                                );
                            }
                        });
                    }

                    return (
                        <div
                            key={i}
                            className={`tab-pane fade ${i === currentSequence[props.level] ? "show active" : ""
                                }`}
                            id={tab.name}
                            role="tabpanel"
                            tabIndex="0"
                        >
                            {/* Check if current tab having more tabs nested */}
                            {tab?.tabs.length !== 0 ? (
                                // If yes: pass the tabs of current tab recursively and increse level by one
                                (console.log("Inside NestedStepperForm:", tab),
                                    (<NestedStepperForm data={tab} level={props.level + 1} />))
                            ) : (
                                // If not: Render the form for current component
                                <Suspense fallback={<div>Loading...</div>}>
                                    {dependsOn ? (
                                        (console.log("Inside Form:", tab), (<Form tabs={tab} />))
                                    ) : (
                                        <DefaultDepends />
                                    )}
                                </Suspense>
                            )}
                        </div>
                    );
                }) : ""}
            </div>
        </>
    );
};

export default NestedStepperForm;
