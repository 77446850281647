import React, { useEffect, useState } from "react";
import { VARIABLES } from "../Constant";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const VerifyEmail = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    var user = JSON.stringify({
      email: data?.email,
    });

    console.log(user);

    const url = VARIABLES.url + "/api/career-send-reset-password-email/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };
    console.log(user);
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsLoading(false);
          console.log(JSON.stringify(response.data.data));
          console.log(JSON.stringify(response.data.data[0].forgot_password_link));
          secureLocalStorage.setItem('forgotpassword_uid', response.data.data[0].uid)
          secureLocalStorage.setItem('forgotpassword_token', response.data.data[0].token)
          document.getElementById("button-button-closemodal").click();
          navigate(`/reset-your-password`);
          // navigate(`/reset-password/confirm/${response.data.data[0].uid}/${response.data.data[0].token}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: `${error?.response?.data?.error}` || `Failed! Try again later.`,
        });
      });
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="passwordreset"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">Password reset</h4>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="brand-logo d-flex justify-content-center">
              <a
                href={VARIABLES.fsc_redirection}
                target="_blank"
                className="logo-link"
                id="button-button-logo"
              >
                <img
                  className="logo-light logo-img logo-img-xl"
                  src="./assets/images/fsc_animated_logo_black.gif"
                  srcSet="./assets/images/fsc_animated_logo_black.gif"
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img logo-img-xl"
                  src="./assets/images/fsc_animated_logo_black.gif"
                  srcSet="./assets/images/fsc_animated_logo_black.gif"
                  alt="logo-dark"
                />
              </a>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <div className="d-flex flex-column">
                        {/* <span className="fs-6 fw-bold mb-1">Password reset</span> */}
                        {props.checkVerified ?
                          <>
                            <small className="mb-3">
                              It appears you are using a system-generated password. For your security, please reset your password to proceed. You will not be able to continue the application until you update your password.
                            </small>
                            <small className="mb-3">
                              Enter your email address
                              below, and we'll send email instructions for setting
                              up a new one.
                            </small>
                          </>
                          :
                          <small className="mb-3">
                            Forgotten your password? Enter your email address
                            below, and we'll send email instructions for setting
                            up a new one.
                          </small>
                        }
                      </div>
                      <label className="form-label">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("email", { required: true })}
                        className="form-control"
                        id="text-form-first_name"
                        type="text"
                        placeholder="Enter Your Email"
                      />
                      {errors.email && (
                        <span className="fs-8 text-danger">
                          Email is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    {isLoading === true ? (
                      <div>Loading...</div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block"
                        id="submit-button-register"
                      >
                        Submit
                      </button>
                    )}
                  </div>

                  <div
                    className="form-note-s2 text-center"
                    style={{ fontSize: "14px" }}
                  >
                    <a
                      href="javascript:void(0)"
                      data-bs-target="#loginModal"
                      data-bs-toggle="modal"
                      id="button-button-signinmodal"
                    >
                      Sign in
                    </a>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div className="nk-block-content text-center">
                <p className="text-soft fs-7">
                  &copy; 2024 Fluidscapes Consultant Pvt.Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {submitMessage && (
            <div
              className="example-alerts position-fixed bottom-0 end-0 p-3"
              style={{ zIndex: 100 }}
            >
              <div className="gy-4">
                <div className="example-alert">
                  <div
                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                  >
                    <em
                      className={`icon ni ni-${submitMessage.icon}-circle`}
                    ></em>{" "}
                    <strong>{submitMessage.message}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
