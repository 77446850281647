import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { VARIABLES } from "../../../Constant";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const ResetPasswordMessage = () => {

    const navigate = useNavigate();

    return (
        <div className="d-flex align-items-center justify-content-center p-5">
            <div className="card p-3" style={{ maxWidth: "600px" }}>
                <div className="brand-logo d-flex justify-content-center">
                    <a
                        href={VARIABLES.fsc_redirection}
                        target="_blank"
                        className="logo-link"
                        id="button-button-logo"
                    >
                        <img
                            className="logo-light logo-img logo-img-xl"
                            src="./assets/images/fsc_animated_logo_black.gif"
                            srcSet="./assets/images/fsc_animated_logo_black.gif"
                            alt="logo"
                        />
                        <img
                            className="logo-dark logo-img logo-img-xl"
                            src="./assets/images/fsc_animated_logo_black.gif"
                            srcSet="./assets/images/fsc_animated_logo_black.gif"
                            alt="logo-dark"
                        />
                    </a>
                </div>
                <div className="card-body">
                    An email has been sent with instructions to reset your password.
                    If you don't receive an email, please make sure you've entered the address you registered with, and also check your spam folder.
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordMessage;
