import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../Context/DashboardContext";
import PendingApplication from "./PendingApplication";
import AppliedApplication from "./AppliedApplication";
import { Helmet } from "react-helmet-async";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import InProcessApplication from "./InProcessApplication";
import { NavLink, useNavigate } from "react-router-dom";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { TypeMasterContext } from "../../../Context/TypeMaster";
import secureLocalStorage from "react-secure-storage";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { JobOpeningContext } from "../../../Context/JobOpeningContext";

const Dashboard = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const { getDropdownData } = useContext(TypemasterDropdownContext)
  const { getGenericDropdownData, getGenericParamsDropdownData } = useContext(GenericDropdownContext)
  const { getRoleType, getGender } = useContext(TypeMasterContext)
  const { jobPostingCount } = useContext(JobOpeningContext);

  const { setActiveTab } = useContext(UserDetailsContext)
  const { appliedEntryCount, pendingEntryCount, applicationUnderProcess } = useContext(DashboardContext);

  const [submitMessage, setSubmitMessage] = useState(null);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/jobopening');
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    setActiveTab(1);
  }, []);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
      getDropdownData(access)
    } else {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
      getDropdownData(access)
    }
  }, [isAccess]);

  useEffect(() => {
    getRoleType();
    getGender();
  }, []);

  return (
    <>
      <Helmet>
        <title>Careers | Job Vacancy In Mumbai | Fluidscapes</title>
        <meta
          name="description"
          content="Discover exciting job opportunities in Fluidscapes Mumbai and seize your chance to embark on a fulfilling career journey. Join us now!"
        />
        <meta name="Keywords" content="job vacancy in mumbai" />
        <link rel="canonical" href="https://career.fluidscapes.in" />
      </Helmet>

      {/* <div class="nk-block-head nk-block-head-sm pb-0">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">Dashboard</h3>
          </div>
        </div>
      </div> */}

      <div class="nk-block pt-0">
        <div class="row g-gs mb-2">
          <div class="col-lg-6 col-sm-6">
            <div class="card h-100 bg-danger d-flex justify-content-center align-items-start">
              <div class="d-flex justify-content-start align-items-center w-50 ms-4">
                <div class="text-white d-flex flex-wrap me-2">
                  <span class="fs-2">{pendingEntryCount}</span>
                </div>
                <h6 class="text-white mb-0 ms-2">Pending Applications</h6>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-sm-6">
            <div class="card h-100 bg-primary d-flex justify-content-center align-items-start">
              <div class="d-flex justify-content-start align-items-center w-50 ms-4">
                <div class="text-white d-flex flex-wrap me-2">
                  <span class="fs-2">{appliedEntryCount}</span>
                </div>
                <h6 class="text-white mb-0 ms-2">Applied Applications</h6>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-sm-6">
            <div class="card h-100 bg-primary d-flex justify-content-center align-items-start">
              <div class="d-flex justify-content-start align-items-center w-50 ms-4">
                <div class="text-white d-flex flex-wrap me-2">
                  <span class="fs-2">{applicationUnderProcess}</span>
                </div>
                <h6 class="text-white mb-0 ms-2">Applications Under Process</h6>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-sm-6">
            <div class="card h-100 bg-danger d-flex justify-content-center align-items-start"
              onClick={handleNavigate}
              style={{ cursor: "pointer" }}
            >
              <div class="d-flex justify-content-start align-items-center w-50 ms-4">
                <div class="text-white d-flex flex-wrap me-2">
                  <span class="fs-2">{jobPostingCount}</span>
                </div>
                <h6 class="text-white mb-0 ms-2" style={{ textWrap: "nowrap" }}>Fluidscapes Job Openings</h6>
                <em class="icon ni ni-chevron-right text-white fs-1"></em>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <>
              {/* <NavLink
                to="/jobopening"
                className="btn btn-primary m-0 mb-4"
                id="sidebar-jobopening"
              >
                <span className="nk-menu-text">FSC Job Openings</span>
                <em class="dd-indc icon ni ni-chevron-right"></em>
              </NavLink> */}

              <PendingApplication setSubmitMessage={setSubmitMessage} />
              <InProcessApplication />
              <AppliedApplication />

              <div>
                {submitMessage && (
                  <div className="example-alerts position-fixed bottom-0 end-0 p-3">
                    <div className="gy-4">
                      <div className="example-alert">
                        <div
                          className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                        >
                          <em
                            className={`icon ni ni-${submitMessage.icon}-circle`}
                          ></em>{" "}
                          <strong>{submitMessage.message}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </div >
    </>
  );
};

export default Dashboard;
