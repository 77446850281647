import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../Context/DashboardContext";
import { NavLink, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import moment from "moment";

const AppliedApplication = () => {
  const navigate = useNavigate();

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const {
    appliedData,
    setAppliedEntries,
    appliedPageCount,
    appliedEntries,
    appliedEntryCount,
    handleAppliedDataPageClick,
    appliedDataLoading,
    getAppliedData,
  } = useContext(DashboardContext);

  const { getAssignmentData, getCandidateExamData } =
    useContext(MDApprovalContext);

  console.log("appliedData:", appliedData);

  const handleEntries = (event) => {
    setAppliedEntries(event.target.value);
  };

  const navigateWorkflow = (data) => {
    secureLocalStorage.setItem("CWFcandidate_job_id", data.id);
    navigate("/workflow");
  };

  // const handleViewStepper = (cand_resume_id, job_opening_id) => {
  //   secureLocalStorage.setItem("RWFcandidate_job_id", cand_resume_id);
  //   // secureLocalStorage.setItem(
  //   //   "RWFrequisition_id",
  //   //   location?.state?.editpost?.requisition?.id
  //   // );
  //   secureLocalStorage.setItem("WF_job_opening_id", job_opening_id);
  //   getAssignmentData(cand_resume_id);
  //   getCandidateExamData(cand_resume_id);
  // };

  useEffect(() => {
    getAppliedData(1, userID);
  }, [appliedEntries]);

  return (
    <>
      <div class="card">
        <div class="card-inner-group">
          <div className="card-inner">
            <h6 class="h6">Applications - Under Process</h6>
          </div>
          {appliedDataLoading ? (
            <div className="mt-2">
              <div className="card-inner"> loading... </div>
            </div>
          ) : (
            <div class="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  {appliedData && appliedData?.data?.length === 0 && (
                    <div className="m-2">No data found</div>
                  )}
                  {appliedData.data?.length > 0 && (
                    <div class="nk-tb-list">
                      <div class="nk-tb-item nk-tb-head">
                        <div class="nk-tb-col tb-col-sm">
                          <span>Job Title</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>Current/Previous Company</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>Excepted Salary</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>Experience</span>
                        </div>
                        <div class="nk-tb-col tb-col-md">
                          <span>Notice Period</span>
                        </div>
                        <div class="nk-tb-col tb-col-md">
                          <span>Applied on</span>
                        </div>
                        <div class="nk-tb-col tb-col-md">
                          <span>Resume</span>
                        </div>
                        <div class="nk-tb-col tb-col-md">
                          <span>View Status</span>
                        </div>

                        {/* <!-- <div class="nk-tb-col tb-col-md"><span>Accept/ Reject</span></div> --> */}
                        <div class="nk-tb-col tb-col-md">
                          <span>Details</span>
                        </div>
                      </div>
                      {appliedData.data?.map((post, index) => (
                        <div class="nk-tb-item" key={index}>
                          <div class="nk-tb-col tb-col-sm">
                            <span class="tb-product">
                              <span class="title">
                                {post?.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              {post?.current_previous_company || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-lead">
                              {post?.expected_salary || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">{post?.experience}</span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              {post?.notice_period || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">{post?.applied_on ?
                              moment(post?.applied_on).format('MMMM Do YYYY, h:mm')
                              : "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col tb-col-md">
                            {post?.candidate_resume?.resume ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={post?.candidate_resume?.resume}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              <NavLink
                                to={"/stepper"}
                                className="btn btn-sm btn-primary"
                                id="button-button-viewstepper"
                                // onClick={() =>
                                //   handleViewStepper(
                                //     post.candidate_resume?.id,
                                //     post.job_opening?.id
                                //   )
                                // }

                                state={{
                                  candidate_id: post.id,
                                  requisition_id:
                                    post?.requisition?.id,
                                  job_opening_id:
                                    post?.job_opening?.id,
                                  resume_id: post?.candidate_resume?.id
                                }}
                              >
                                <em class="icon ni ni-eye"></em>
                              </NavLink>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <NavLink
                              to={"/candidateotherdetails"}
                              state={{ editpost: post, from: "inprocess" }}
                              className="btn btn-sm btn-primary"
                              id="button-button-candidateotherdetails"
                            >
                              <em className="icon ni ni-eye"></em>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </table>
              </div>
            </div>
          )}
          <div className="card-inner">
            <div className="nk-block-between-md g-3">
              <div className="g">
                <ReactPaginate
                  key={appliedPageCount}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={appliedPageCount}
                  marginPagesDisplayed={2}
                  // pageRangeDisplayed={3}
                  onPageChange={(data) =>
                    handleAppliedDataPageClick(data, userID)
                  }
                  containerClassName={
                    "pagination justify-content-center justify-content-md-start"
                  }
                  pageClassName={"paginate_button page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"paginate_button page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
              <div className="g">
                <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                  <div>
                    Total entries:&nbsp;
                    <span className="fw-bold">
                      {appliedEntryCount === null ? 0 : appliedEntryCount}
                    </span>
                  </div>
                  <div>No of entries </div>
                  <div>
                    <select
                      className="form-select js-select2"
                      data-search="on"
                      data-dropdown="xs center"
                      value={appliedEntries}
                      onChange={handleEntries}
                    >
                      <option value="5">5</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppliedApplication;
